import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  PlaylistCardContainer,
  PlaylistCardTitle,
  PlaylistCardSeparator,
  PlaylistCardIcons,
  PlaylistCardDetails,
  PlaylistCardDetailItem,
  PlaylistCardDetailsRow,
  PlaylistCardTitleRow,
  PlaylisCardTime,
  PlaylistCardIcon,
} from './components';
import video from 'assets/icons/Video.webp';
import audio from 'assets/icons/Audio.webp';
import readings from 'assets/icons/Readings.webp';
import ClockIcon from 'components/icons/ClockIcon';
import { Artifact, PLAYLIST_FILTERS } from '../home/playlist/typings';
import { selectWeekNameById } from 'modules/loading-screen/weeks/selectors';
import { filtersDefinitions } from '../home/playlist/utils';
import DoneIcon from 'components/icons/DoneIcon';
import theme from 'utils/theme';
import HeartIcon from 'components/icons/HeartIcon';
import HeartFilledIcon from 'components/icons/HeartFilledIcon';
import { selectPlaylistListSelected } from 'modules/home/playlist/selectors';
import {
  deleteSelectedArtifact,
  setSelectedArtifact,
  updatePlaylistSelected,
} from 'modules/home/playlist/actions';

type PlaylistCardProps = {
  artifact: Artifact;
  noMarginBottom?: boolean;
  onPlaylistCardClick: (artifact: Artifact) => void;
};

const PlaylistCard = ({
  artifact,
  noMarginBottom = false,
  onPlaylistCardClick,
}: PlaylistCardProps) => {
  const weekName = useSelector(selectWeekNameById(artifact.weekId));
  const dispatch = useDispatch();
  const listSelected = useSelector(selectPlaylistListSelected);

  const icons = useMemo(
    () => (
      <>
        {artifact.type === 'reading' && artifact.reading?.audioUrl && (
          <PlaylistCardIcon src={audio} alt="audio" />
        )}
        {artifact.type !== 'reading' &&
          filtersDefinitions[PLAYLIST_FILTERS.LISTEN].includes(
            artifact.type,
          ) && <PlaylistCardIcon src={audio} alt="audio" />}
        {filtersDefinitions[PLAYLIST_FILTERS.READ].includes(artifact.type) && (
          <PlaylistCardIcon src={readings} alt="reading" />
        )}
        {filtersDefinitions[PLAYLIST_FILTERS.WATCH].includes(artifact.type) && (
          <PlaylistCardIcon src={video} alt="video" />
        )}
      </>
    ),
    [artifact],
  );

  const handleFavouriteItem = useCallback(() => {
    let arrayPlaylistSelected = listSelected;
    if (listSelected.some((el: string) => el === artifact.id)) {
      arrayPlaylistSelected = arrayPlaylistSelected.filter(
        (el: string) => el !== artifact.id,
      );
      dispatch(deleteSelectedArtifact({ idArtifactSelected: artifact.id }));
    } else {
      arrayPlaylistSelected = [...arrayPlaylistSelected, artifact.id];
      dispatch(setSelectedArtifact({ idArtifactSelected: artifact.id }));
    }
    dispatch(updatePlaylistSelected(arrayPlaylistSelected));
  }, [artifact.id, dispatch, listSelected]);

  const isFavouriteIcon = (
    <div onClick={handleFavouriteItem}>
      {listSelected.some((el: string) => el === artifact.id) ? (
        <HeartFilledIcon width={28} height={28} />
      ) : (
        <HeartIcon width={28} height={28} />
      )}
    </div>
  );

  const handleClickArtifact = () => onPlaylistCardClick(artifact);

  return (
    <PlaylistCardContainer data-testId='card-playlist' noMarginBottom={noMarginBottom}>
      <PlaylistCardTitleRow>
        <PlaylistCardTitle data-testId='title-playlist' onClick={handleClickArtifact}>
          {artifact.title}
        </PlaylistCardTitle>
        {artifact.completed ? (
          <DoneIcon width={32} height={32} />
        ) : (
          isFavouriteIcon
        )}
      </PlaylistCardTitleRow>
      <PlaylistCardSeparator />
      <PlaylistCardDetailsRow data-testId='detail-playlist' onClick={handleClickArtifact}>
        <PlaylistCardIcons>{icons}</PlaylistCardIcons>
        <PlaylistCardDetails>
          <PlaylistCardDetailItem>{weekName}</PlaylistCardDetailItem>
          <PlaylistCardDetailItem>
            <ClockIcon
              color="rgba(255, 255, 255, 0.6)"
              width={theme.typography.large18}
              height={theme.typography.large18}
            />
            <PlaylisCardTime>{artifact.duration} min</PlaylisCardTime>
          </PlaylistCardDetailItem>
        </PlaylistCardDetails>
      </PlaylistCardDetailsRow>
    </PlaylistCardContainer>
  );
};

export default PlaylistCard;
